<template>
  <div>
<!--    <GraficoBar />-->
<!--    <v-card class="mb-3" dark color="primary">-->
<!--      <v-card-title>Bem-vindo, Vitor </v-card-title>-->
<!--    </v-card>-->

    <v-card class="mt-1 pa-0 mx-0" elevation="2">
      <v-card-title>
        <v-icon color="primary" class="mr-2" size="35">mdi-cash-fast</v-icon> Comissões de Indicação
      </v-card-title>
      <v-divider/>
      <v-card-text>
        <v-row>
          <v-col cols="12" sm="3" md="3">
            <v-card color="primary" dark rounded elevation="1">
              <v-card-title class="pa-3">
                <v-row>
                  <v-col class="text-center pa-1">
                    <div v-if="!carregando"> {{dados.qtdpontos | formataDinheiro}}</div>
                    <v-skeleton-loader v-else type="text" height="5px" width="100%"></v-skeleton-loader>
                  </v-col>
                </v-row>
              </v-card-title>
              <v-divider></v-divider>
              <v-card-text>
                <v-row>
                  <v-col class="text-center pa-1">
                    <v-progress-circular size="15" v-if="carregando" absolute indeterminate color="white" class="mr-1"></v-progress-circular>
                    Bônus Concedidos
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12" sm="3" md="3">
            <v-card color="primary" dark rounded elevation="1">
              <v-card-title class="pa-3">
                <v-row>
                  <v-col class="text-center pa-1">
                    <div v-if="!carregando"> {{ dados.qtdpontosresgatados | formataDinheiro}}</div>
                    <v-skeleton-loader v-else type="text" height="5px" width="100%"></v-skeleton-loader>
                  </v-col>
                </v-row>
              </v-card-title>
              <v-divider></v-divider>
              <v-card-text>
                <v-row>
                  <v-col class="text-center pa-1">
                    <v-progress-circular size="15" v-if="carregando" absolute indeterminate color="white" class="mr-1"></v-progress-circular>
                    Bônus Resgatados
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12" sm="3" md="3">
            <v-card color="primary" dark rounded elevation="1">
              <v-card-title class="pa-3">
                <v-row>
                  <v-col class="text-center pa-1">
                    <div v-if="!carregando"> {{ dados.qtddevolucao  * -1 | formataDinheiro}}</div>
                    <v-skeleton-loader v-else type="text" height="5px" width="100%"></v-skeleton-loader>
                  </v-col>
                </v-row>
              </v-card-title>
              <v-divider></v-divider>
              <v-card-text>
                <v-row>
                  <v-col class="text-center pa-1">
                    <v-progress-circular size="15" v-if="carregando" absolute indeterminate color="white" class="mr-1"></v-progress-circular>
                    Bônus Cancelados
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12" sm="3" md="3">
            <v-card color="primary" dark rounded elevation="1">
              <v-card-title class="pa-3">
                <v-row>
                  <v-col class="text-center pa-1">
                    <div v-if="!carregando"> {{ dados.qtdsaldopontos | formataDinheiro}}</div>
                    <v-skeleton-loader v-else type="text" height="5px" width="100%"></v-skeleton-loader>
                  </v-col>
                </v-row>
              </v-card-title>
              <v-divider></v-divider>
              <v-card-text>
                <v-row>
                  <v-col class="text-center pa-1">
                    <v-progress-circular size="15" v-if="carregando" absolute indeterminate color="white" class="mr-1"></v-progress-circular>
                    Bônus Disponíveis
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>

          <!--                <v-col cols="12" md="4">-->
          <!--                  <v-card color="primary" dark rounded elevation="1">-->
          <!--                    <v-progress-linear v-if="false" absolute indeterminate color="white"></v-progress-linear>-->
          <!--                    <v-card-title>-->
          <!--                      <v-row>-->
          <!--                        <v-col class="text-center pa-1">-->
          <!--                          <div v-if="true" class="pa-1">-->
          <!--                            <v-progress-linear-->
          <!--                                height="24"-->
          <!--                                rounded-->
          <!--                                :color="corProgresso(0)"-->
          <!--                                :value="volumeProgresso(0)"-->
          <!--                            >-->
          <!--                              <strong :class="volumeProgresso(0) <= 60 ? '' : 'white&#45;&#45;text'" style="font-size: large">{{opcaoProgesso(0)}}</strong>-->
          <!--                            </v-progress-linear>-->
          <!--                          </div>-->
          <!--                          <v-skeleton-loader v-else class="mx-auto mt-3" width="100%" height="3" type="text"></v-skeleton-loader>-->
          <!--                        </v-col>-->
          <!--                      </v-row>-->
          <!--                    </v-card-title>-->
          <!--                    <v-divider></v-divider>-->
          <!--                    <v-card-text>-->
          <!--                      <v-row>-->
          <!--                        <v-col class="text-center pa-1">Meu %</v-col>-->
          <!--                      </v-row>-->
          <!--                    </v-card-text>-->
          <!--                  </v-card>-->
          <!--                </v-col>-->
          <!--                <v-col cols="12">-->
          <!--                  <fieldset class="text-center rounded pa-2 fildset-border-dash-int">-->
          <!--                    <legend class="px-2">Progresso</legend>-->
          <!--                    <v-progress-linear :value="0" height="32" rounded color="primary">-->
          <!--                      <strong v-if="false" class="white&#45;&#45;text" style="font-size: large">{{ (0).toFixed(2) }} %</strong>-->
          <!--                      <strong v-else class="white&#45;&#45;text" style="font-size: large">0.00 %</strong>-->
          <!--                    </v-progress-linear>-->
          <!--                  </fieldset>-->
          <!--                </v-col>-->
        </v-row>
      </v-card-text>
    </v-card>

    <v-card class="mt-5 pa-0 mx-0" elevation="2">
      <v-card-title>
        <v-icon color="primary" class="mr-2" size="32">mdi-account-supervisor</v-icon> Minhas Indicações
      </v-card-title>
      <v-divider/>
      <v-card-text class="px-0 pt-0">
        <v-data-table
            :loading="carregando"
            loading-text="Carregando Informações"
            :headers="[
                { text: 'Empresa', value: 'empresa', align:'left', class: 'pl-5', sortable: false},
                { text: 'Cliente Indicado', value: 'cliente', align:'left', sortable: false},
                { text: 'Bônus Lançado', value: 'qtdpontos', align:'center', sortable: false},
                { text: 'Bônus Resgatado', value: 'qtdpontosresgatados', align:'center', sortable: false},
                { text: 'Bônus Cancelado', value: 'qtdsaldodevolucao', align:'center', sortable: false},
                { text: 'Saldo de Bônus', value: 'qtdsaldopontos', align:'center', sortable: false},
                { text: 'Data da Compra', value: 'data', align:'center', sortable: false},
                { text: 'Data de Validade', value: 'datavalidade', align:'center', sortable: false},
                { text: 'Status', value: 'status', align:'center', sortable: false},
            ]"
            :items="dados.lista"
            hide-default-footer
            class="custom-mobile-row"
            item-class="custom-mobile-row"
        >
          <template v-slot:item.qtdpontos="{ item }">
            {{ item.qtdpontos | formataDinheiro }}
          </template>
          <template v-slot:item.qtdpontosresgatados="{ item }">
            {{ item.qtdpontosresgatados | formataDinheiro }}
          </template>
          <template v-slot:item.qtdsaldodevolucao="{ item }">
            {{ item.qtdsaldodevolucao * -1 | formataDinheiro }}
          </template>
          <template v-slot:item.qtdsaldopontos="{ item }">
            <v-tooltip bottom color="error" class="d-none d-md-block" v-if="item.qtdsaldopontos < 0">
              <template v-slot:activator="{ on }">
                <span v-on="on" :class="item.qtdsaldopontos < 0 ? 'red--text' : ''">
                  {{ item.qtdsaldopontos | formataDinheiro }}
                </span>
              </template>
              <span>
                Saldo negativo devido a resgate e devolução de compra
              </span>
            </v-tooltip>
            <span v-else>
              {{ (item.qtdsaldopontos > 0 && !(item.diasvalidade>=-1) ? 0 : item.qtdsaldopontos) | formataDinheiro }}
            </span>
          </template>
          <template v-slot:item.status="{ item }">
            <div>
              <v-chip class="d-sm-none" :class="corProgresso(item.qtdsaldopontos == 0 ? 'Resgatado' : item.qtdsaldopontos > 0 && !(item.diasvalidade>=-1) ? 'Vencido' : item.qtdsaldopontos > 0 ? 'Disponível' : 'Cancelado')" x-small>
                {{ item.qtdsaldopontos == 0 ? 'Resgatado' : item.qtdsaldopontos > 0 && !(item.diasvalidade>=-1) ? 'Expirado' : item.qtdsaldopontos > 0 ? 'Disponível' : 'Cancelado' }}
              </v-chip>
              <v-chip class="d-none d-sm-block" :class="corProgresso(item.qtdsaldopontos == 0 ? 'Resgatado' : item.qtdsaldopontos > 0 && !(item.diasvalidade>=-1) ? 'Vencido' : item.qtdsaldopontos > 0 ? 'Disponível' : 'Cancelado')" small>
                {{ item.qtdsaldopontos == 0 ? 'Resgatado' : item.qtdsaldopontos > 0 && !(item.diasvalidade>=-1) ? 'Expirado' : item.qtdsaldopontos > 0 ? 'Disponível' : 'Cancelado' }}
              </v-chip>
            </div>
<!--            <div v-else>-->
<!--              <v-chip class="d-sm-none success" x-small>-->
<!--                Resgatado-->
<!--              </v-chip>-->
<!--              <v-chip class="d-none d-sm-block success" small>-->
<!--                Resgatado-->
<!--              </v-chip>-->
<!--            </div>-->
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { mapState } from "vuex";
import axios from "axios";


// import axios from "axios";
export default {
  name: "Inicio",
  data: () => ({
    dados: {
      qtdpontos: 0,
      qtdpontosresgatados: 0,
      qtddevolucao: 0,
      qtdsaldopontos: 0
    },
    carregando: false,
    carregandoSkeleton: true,
  }),
  computed: {
    ...mapState(["backendUrl", "pgLimit"]),
  },
  methods: {
    listar() {
      this.carregando = true
      return axios.post(`${this.backendUrl}clube/listar`, {
      }).then( (res) => {
        if (res.data.lista == null) {
          this.dados =  {
            qtdpontos: 0,
            qtdpontosresgatados: 0,
            qtddevolucao: 0,
            qtdsaldopontos: 0
          }
        } else {
          this.dados = res.data
        }
        this.carregando = false
      }).catch(() => {
      })
    },
    corProgresso(status){
      if(status == 'Disponível'){
        return 'blue darken-1 white--text'
      } else if(status == 'Resgatado'){
        return 'success'
      } else if(status == 'Cancelado'){
        return 'black white--text'
      } else if(status == 'Vencido'){
        return 'error'
      }
    },
    opcaoProgesso(per){
      if(per <= 18){
        return 'Muito Baixa'
      } else if(per <= 24){
        return 'Baixa'
      } else if(per <= 28){
        return 'Média'
      } else if(per <= 31){
        return 'Boa'
      } else if(per > 31){
        return 'Muito Boa'
      }
    },
    volumeProgresso(per){
      if(per <= 10) {
        return 5
      }else if(per <= 15){
        return 10
      }else if(per <= 18){
        return 15
      }else if(per <= 20){
        return 20
      } else if(per <= 24){
        return 30
      }else if(per <= 26){
        return 40
      } else if(per <= 28){
        return 60
      }else if(per <= 30){
        return 70
      } else if(per <= 31){
        return 80
      } else if(per <= 32){
        return 85
      }else if(per > 32) {
        return 95
      }
    },
    async init() {
      this.listar();
    },
  },
  created() {
    this.init()
  },
};
</script>

<style>
@media only screen and (max-width: 600px) {
  .v-data-table > .v-data-table__wrapper > table > tbody > tr,
  .v-data-table > .v-data-table__wrapper > table > thead > tr,
  .v-data-table > .v-data-table__wrapper > table > tfoot > tr {
    padding-top: 6px !important;
  }

  .v-data-table > .v-data-table__wrapper > table > tbody > tr > td,
  .v-data-table > .v-data-table__wrapper > table > thead > tr > td,
  .v-data-table > .v-data-table__wrapper > table > tfoot > tr > td {
    height: 12px !important; /* Altere para a altura desejada */
    padding-top: 14px !important;
    padding-bottom: 14px !important;
    min-height: 12px !important; /* Altere para a altura desejada */
    font-size: 0.8em !important;
  }

  .v-data-table__mobile-row__header {
    font-weight: 500 !important;
  }
}

</style>